import { Component, ViewChild } from "@angular/core";
import { FormArray, FormControl, Validators } from "@angular/forms";
import { BaseFormDrawer } from "@app/drawers/base-form";
import { Const } from "@const/Const";
import { DateUtil } from "@services/date-utils";
import { InputHelper } from "@services/input-helper";
import { MasterData } from "@services/master.data";
import { Utils } from "@services/utils";
import { FormUtil } from "@services/form-util";
import { AddressUSSmartyMetadata, Organization, Warehouse } from "@wearewarp/types/data-model";
import { ApiMethod } from "@app/enum";
import { FormInputUploadImages } from "../base/form-input-upload-images/comp";
import { DialogService } from "@dialogs/dialog.service";
import { WarehouseDataForMap } from "./warehouse-form-v2-map/interface";
import { BizUtil } from "@services/biz";

@Component({
  selector: '[warehouse-form-v2]',
  templateUrl: './warehouse-form-v2.html',
  styleUrls: ['./warehouse-form-v2.scss', '../../../styles/form-v2.scss', '../../../styles/row-col.scss', '../../../styles/time-window.scss']
})
export class WarehouseFormV2 extends BaseFormDrawer {
  client: any;  // đi từ màn customer
  get isComeFromClientScreen(): boolean {
    return !!this.client;
  }
  listClients = [];
  addressType = Const.AddressTypeArray;
  serviceOptions = [
    {label: 'Pickup services', items: MasterData.ShipmentServiceOptionsPickup},
    {label: 'Delivery services', items: MasterData.ShipmentServiceOptionsDelivery},
    {label: 'Additional services', items: MasterData.ShipmentServiceOptionsAddition},
  ]
  listTimezones = DateUtil.listTimezones;

  protected formGroupDeclaration: FormGroupDeclaration = {
    name: { label: 'Location name', required: true },
    isHazmatLocation: { label: 'Hazmat Location', type: 'boolean' },
    clientIds: {label: 'Customer', required: true, type: 'array' },
    addressType: { label: 'Address Type: ' },
    warehouseType: { label: 'Warehouse Type', required: true, initialValue: Const.WarehouseTypes.normal },
    orgId: { label: 'Organization', placeHolder: "Select Organization" },
    pickAddr: { label: 'Address', required: true },
    serviceOptions: { label: 'Service Options', type: 'array', initialValue: [] },
    operatingHours: { label: 'Operating Hours', type: 'formArray', childItem: {
      isOpen: { label: '', type: 'boolean' },
      fromTime: { label: '', getValue: DateUtil.getHHmm, formatValue: DateUtil.fromHHmm, placeHolder: 'From time' },
      toTime: { label: '', getValue: DateUtil.getHHmm, formatValue: DateUtil.fromHHmm, placeHolder: 'To time' },
    }, initialValue: [
      { isOpen: true }, // monday
      { isOpen: true },
      { isOpen: true },
      { isOpen: true },
      { isOpen: true },
      { isOpen: true },
      { isOpen: false } // sunday
    ]},
    questions: { label: 'Questions', type: 'formArray', childItem: {
      question: { label: '', required: false, readOnly: true, submitReadOnly: true },
      answer: { label: '', required: true },
    }, initialValue: []},
    autoRecipients: { label: 'Auto Recipients', type: 'formArray', childItem: {
      name: { label: 'Contact Person Name', placeHolder: 'Contact Person Name' },
      email: { label: 'Email', required: true, placeHolder: 'Email Address', validators: Validators.email },
      type: { label: 'Type', required: true, placeHolder: 'Select type', initialValue: 'to'},
    }},
    requireAppointment: {label: 'Appointment Required', type: 'boolean'},
    phone: { label: 'Contact Phone Number', inputType: 'tel', getValue: InputHelper.getValuePhone, formatValue: InputHelper.formatPhone },
    phoneExtension: { label: '', placeHolder: 'Ext' },
    email: { label: 'Contact Person Email', validators: Validators.email },
    contactName: { label: 'Contact Person Name' },
    pickDetails: {label: 'Pickup Details:', required: false, type: 'formGroup', childItem: {
      instructions: { label: 'Location instructions' },
      instructionImgs: { label: 'Images'},
      accessCode: { label: 'Access Code' },
      note: { label: 'Internal Notes' },
      customSetting : {label: '', type: 'formArray', childItem: {
        id : {label: ''},
        instructions: { label: 'Location instructions' },
        instructionImgs: { label: 'Images'},
        note: { label: 'Internal Notes' },
      }},
    }},
    dropDetails: {label: 'Delivery Details:', required: false, type: 'formGroup', childItem: {
      instructions: { label: 'Location instructions' },
      instructionImgs: { label: 'Images'},
      accessCode: { label: 'Access Code' },
      note: { label: 'Internal Notes' },
      customSetting : {label: '', type: 'formArray', childItem: {
        id : {label: ''},
        instructions: { label: 'Location instructions' },
        note: { label: 'Internal Notes' },
        instructionImgs: { label: 'Images'},
      }},
    }},
    secondaryContact: {label: 'Secondary Contact', required: false, type: 'formGroup', childItem: {
      contactName: { label: 'Contact Person Name' },
      phone: { label: 'Contact Phone Number', inputType: 'tel', getValue: InputHelper.getValuePhone, formatValue: InputHelper.formatPhone },
      phoneExtension: { label: '', placeHolder: 'Ext' },
      email: { label: 'Contact Person Email', validators: Validators.email },
    }},
    supportManifestFunction: { label: 'Enable Manifest Feature', type: 'boolean' },
    isWarpCrossdockLocation: { label: 'Warp Crossdock Location', type: 'boolean' },
    requiredCheckIn: { label: 'Require Check-In', type: 'boolean' },
  }

  public row1: FormRow = {columns: [
    {key: 'name', span: 11, offset: 0},
    {key: 'clientIds', span: 12, offset: 1},
    {key: 'warehouseType', span: 11},
    {key: 'orgId', span: 12, offset: 1},
  ]};
  public row2: FormRow = {columns: [
    {key: 'addressType', span: 11, offset: 0},
    {key: 'pickAddr', span: 12, offset: 1},
  ]};
  public row3: FormRow = {columns: [
    {key: 'accessCode', span: 16, offset: 0},
    {key: 'instructions', span: 11, offset: 0},
    {key: 'note', span: 11, offset: 1},
  ]};
  public row4: FormRow = {columns: [
    {key: 'contactName', span: 11, offset: 0},
    {key: 'phone', span: 11, offset: 1},
    {key: 'email', span: 11, offset: 0},
  ]};
  public row5: FormRow = {columns: [
    {key: 'name', span: 7, offset: 0},
    {key: 'email', span: 7, offset: 0},
    {key: 'type', span: 7, offset: 0},
    {key: 'action', span: 3, offset: 0},
  ]};
  public dayLables = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
  public recipientTypes = [
    { value: 'to', label: 'To' },
    { value: 'cc', label: 'CC' },
  ];
  public warehouseTypes = Object.values(Const.WarehouseTypes).map(type => {
    return { value: type, label: Const.getWarehouseTypeText(type) }
  })

  listClientIds = [];
  isMultipleClients: boolean = false;

  @ViewChild('uploadImagesForm', { static: false }) uploadImagesForm: FormInputUploadImages;

  dataForMap: WarehouseDataForMap
  showTimezone() {
    const str = DateUtil.timezoneStandardToUsShort(this.dataForMap.address.timezone);
    return str || 'N/A';
  }
  hasGpsLocation(): boolean {
    return !!this.dataForMap?.location?.latitude;
  }
  hasBounds(): boolean {
    return !!this.dataForMap.bounds?.northeast?.latitude && !!this.dataForMap.bounds?.southwest?.latitude;
  }

  private buildDataForMap(warehouse: Warehouse) {
    this.dataForMap = warehouse ? {
      id: warehouse.id,
      address: BizUtil.buildResponseAddress(warehouse.pickAddr),
      location: {
        latitude: warehouse.pickAddr?.metadata?.latitude,
        longitude: warehouse.pickAddr?.metadata?.longitude,
      },
      bounds: warehouse.pickAddr?.metadata?.bounds,
    } : undefined;
  }

  protected buildUrl(method: ApiMethod): string {
    let url = this.getApiUrl();
    if (!url) return url;
    let id = (<any>this.model)?.id || (<any>this.model)?._id;
    if (id) {
      url = `${url}/${id}`;
    }
    return url;
  }

  getApiListDataForFilter(key: string): string|undefined {
    switch (key) {
      case 'clientIds': return Const.APIURI_CLIENTS_FOR_FILTER;
      default: return
    }
  }

  onListClientsChanged(event) {
    let clientIds = event;
    this.isMultipleClients = clientIds.length > 1;
    this.listClientIds = clientIds;
    this.changeListItemRow3(clientIds);
  }
  changeListItemRow3(Ids) {

    // nếu chưa tồn tại trong formArray thì thêm vào formArray, nếu trong formArray đã tồn tại mà không có trong listClientIds thì xóa đi
    let customSettingOfPick = this.formInput.get('pickDetails.customSetting').value;
    let customSettingOfDrop = this.formInput.get('dropDetails.customSetting').value;
    let customSettingOfPickIds = customSettingOfPick.map(it => it.id);
    let customSettingOfDropIds = customSettingOfDrop.map(it => it.id);
    for (let i = 0; i < Ids.length; i++) {
      let id = Ids[i];
      if (customSettingOfPickIds.indexOf(id) == -1) {
        this.addItemToFormArrayOfObject('pickDetails','customSetting', {id: id});
      }
      if (customSettingOfDropIds.indexOf(id) == -1) {
        this.addItemToFormArrayOfObject('dropDetails','customSetting', {id: id});
      }

    }
    for (let i = 0; i < customSettingOfPick.length; i++) {
      let id = customSettingOfPick[i].id;
      if (Ids.indexOf(id) == -1) {
        this.removeItemInFormArrayOfObject('pickDetails','customSetting', i);
      }
    }
    for (let i = 0; i < customSettingOfDrop.length; i++) {
      let id = customSettingOfDrop[i].id;
      if (Ids.indexOf(id) == -1) {
        this.removeItemInFormArrayOfObject('dropDetails','customSetting', i);
      }
    }
  // nếu chỉ có 1 client thì truyền data của client đó vào form pickDetails và dropDetails và xóa customSetting
    if(Ids.length == 1){
      let pickData = this.getFormItemValue('pickDetails.customSetting[0]');
      let dropData = this.getFormItemValue('dropDetails.customSetting[0]');
      this.setItemValue('pickDetails', pickData);
      this.setItemValue('dropDetails', dropData);
      this.removeAllItemInFormArray('pickDetails.customSetting');
      this.removeAllItemInFormArray('dropDetails.customSetting');
    }
  // nếu thay đổi từ 1 client lên thành 2 thì truyền data ở form pickDetails và dropDetails vào customSetting của client đâu tiên
    if(Ids.length == 2 && customSettingOfPickIds.length < 2 && customSettingOfDropIds.length < 2){
      let pickData = this.getFormItemValue('pickDetails');
      let dropData = this.getFormItemValue('dropDetails');
      pickData.id = Ids[0];
      dropData.id = Ids[0];
      this.setItemValue('pickDetails.customSetting[0]', pickData);
      this.setItemValue('dropDetails.customSetting[0]', dropData);
      // xóa data ở form pickDetails và dropDetails
      this.setItemValue('pickDetails.instructions', null);
      this.setItemValue('dropDetails.instructions', null);
      this.setItemValue('pickDetails.note', null);
      this.setItemValue('dropDetails.note', null);
      this.setItemValue('pickDetails.instructionImgs', []);
      this.setItemValue('dropDetails.instructionImgs', []);
    }
  }
  getValueByKey(key: string) {
    const keys = key.split('.');
    let f: any= this.formInput;
    for (const k of keys) {
      f = f?.get(k);
    }
    if (f) {
      return f.value;
    }
    return [];
  }
  removeItemInFormArrayOfObject(key1: string, key2: string, index: number) {
    let fa = <FormArray>this.formInput.get(key1).get(key2);
    fa.removeAt(index);
  }
  removeAllItemInFormArray(key: string) {
    let fa = this.getFormArray(key);
    fa.clear();
  }
  public getWarehouseTypeText(type) {
    return Const.getWarehouseTypeText(type);
  }
  public getOrganizationText(orgId) {
    const organization = this.organizations.find(it => it.id === orgId);
    return organization?.name;
  }

  addItemToFormArrayOfObject(key1: string, key2: string, bindData = null) {
    let fa = <FormArray>this.formInput.get(key1).get(key2);
    let declaration = this.formGroupDeclaration[key1].childItem[key2];
    let f = FormUtil.createChildItem(declaration, bindData);
    fa.push(f);
  }
  getNameClient(clientId) {
    let client = this.listClients.find(it => it.id == clientId);
    return client?.name || '' ;
  }
  isHistoryExisting: boolean = false
  get serviceOptionsSelectDesc() {
    let count = this.formInput?.get('serviceOptions')?.value?.length ?? 0;
    return `${count} ${count > 1 ? 'options' : 'option'}`;
  }

  get shouldShowClient(): boolean {
    return this.isAdmin && !this.isComeFromClientScreen;
  }

  get formTitle() {
    let str = '';
    if (!this.model) {
      str = 'Create new location';
    } else {
      if (!this.isEditOrCreate) {
        str = `${this.model.name.replace(/ /g, '&nbsp;')}`;
      } else {
        str = `Edit location`;
      }
    }
    return str;
  }
  get formTitleOther() {
    let str = '';
    if (this.model) {
      const clients = this.model.clients || [];
      if (!this.isEditOrCreate) {
        str = `${clients.map(it => it.name).join(', ')}`;
      } else {
        let date = this.displayDateTime(this.model?.update?.when, 'MM-DD-YYYY h:mm a');
        str = `Last updated on ${date}`;
      }
    }
    return str;
  }

  constructor() {
    super();
  }

  ngOnInit(): void {
    if (this.client) {
      this.formGroupDeclaration.clientIds.hidden = true;
    }
    this.fetchData();
    this.getQuestions();
    this.getOrganizations();
    super.ngOnInit();
    this.buildDataForMap(this.model);
  }

  get shouldCreateFormImmediately() {
    return false;
  }

  private getDataDone(err = null) {
    if (err) {
      this.showErr(err);
      return;
    }

    this.createFormInput(this.model || {});
    this.setEnableFormGroup(true);
    if (this.isAdminReadOnlyRole) {
      this.setEnableFormGroup(false);
    }
  }

  isLoadingOrganization: boolean = false;
  organizations: Organization[] = [];
  private getOrganizations() {
    this.isLoadingOrganization = true;
    const url = Const.APIV2(`${Const.APIURI_ORGANIZATIONS}?limit=-1`);
    this.api.GET(url).subscribe(
      (resp) => {
        this.isLoadingOrganization = false;
        this.organizations = resp.data.list_data;
      },
      (err) => {
        this.isLoadingOrganization = false;
        this.showErr(err);
      }
    );
  }

  private questions: string[] = [];
  private getQuestions() {
    const url = Const.APIV2(`${Const.APIURI_METADATA}/ORG_0/WAREHOUSE/question-location-settings`);
    this.api.GET(url).subscribe(
      resp => {
        const value = resp.value || '[]';
        const settings = JSON.parse(value);
        const questions = settings.map(it => it.question);
        this.questions = questions;
        this.getDataDone();
      }, err => {
        this.getDataDone(err);
      }
    );
  }

  fetchData(){
    if(!this.model) return;
    let url = `${Const.APIURI_AUDIT_LOGS}/one?entity=warehouses&objectId=${this.model._id}`;
    this.api.GET(url).subscribe(
      resp => {
        this.isHistoryExisting = Utils.isObjectNotEmpty(resp?.data);
      }, err => {
        // skip
      }
    );
  }

  getTextDayOpen(index: number): string {
    let isOpen = this.getItemValue(`operatingHours[${index}].isOpen`);
    return isOpen ? 'Open' : 'Closed';
  }

  get needUpdate(): boolean {
    let fileKeys = Object.keys(this.fileToUpload);
    if (fileKeys?.length || this.isDeletedImage) {
      return true;
    }
    return super.needUpdate;
  }

  protected getApiUrl(): string {
    return Const.APIURI_WAREHOUSES;
  }

  protected getFormData_JSON(isCreateNew: boolean): object {
    let json: any = super.getFormData_JSON(true); // always get full data
    if (isCreateNew && this.client) {
      json.clientIds = [this.client.id];
    }
    if(this.isMultipleClients){
      let customSettingOfPick = json['pickDetails']['customSetting'];
      let customSettingOfDrop = json['dropDetails']['customSetting'];

      const result: { [key: string]:any } = {};
      customSettingOfPick.forEach(element => {
        const customSetting = {
          pickDetails: {
            instructions: element.instructions,
            note: element.note,
            instructionImgs: element.instructionImgs
          }
        }
        result["CUSTOMER_" + element.id] = customSetting;
      });
      customSettingOfDrop.forEach(element => {
        const customSetting = {
          dropDetails: {
            instructions: element.instructions,
            note: element.note,
            instructionImgs: element.instructionImgs
          }
        }
        if(result["CUSTOMER_" + element.id]){
          result["CUSTOMER_" + element.id].dropDetails = customSetting.dropDetails;
        }else{
          result["CUSTOMER_" + element.id] = customSetting;
        }
      });
      json['customSetting'] = result;
    }

    delete json['pickDetails']['customSetting'];
    delete json['dropDetails']['customSetting'];
    json['questions'] = (json.questions || []).filter(it => it.answer !== 'default');
    return json;
  }

  protected beforeBindModel(model): any {
    if (model?.clients?.length) {
      model.clientIds = model.clients.map(it => it.id);
      this.listClients = model.clients;
    }
    if(model?.customSetting){
      let customSetting = model.customSetting;
      let customSettingOfPick = [];
      let customSettingOfDrop = [];
      for (let key in customSetting) {
        if(key.indexOf('CUSTOMER_') > -1){
          let id = key.replace('CUSTOMER_', '');
          let obj = customSetting[key];
          if(obj.pickDetails){
            obj.pickDetails.id = id;
            customSettingOfPick.push(obj.pickDetails);
          }
          if(obj.dropDetails){
            obj.dropDetails.id = id;
            customSettingOfDrop.push(obj.dropDetails);
          }
        }
      }
      model.pickDetails.customSetting = customSettingOfPick;
      model.dropDetails.customSetting = customSettingOfDrop;
    }
    if(!model?.customSetting){
      // nếu không có customSetting thì tạo customSetting default cho từng client
      if (model.pickDetails && model.dropDetails) {
        let customSettingOfPick = [];
        let customSettingOfDrop = [];
        for (let i = 0; i < model.clientIds?.length; i++) {
          let id = model.clientIds[i];
          customSettingOfPick.push({id: id, instructions: model.pickDetails.instructions || "", note: model.pickDetails.note || "", instructionImgs: model.pickDetails.instructionImgs ?? []});
          customSettingOfDrop.push({id: id, instructions: model.dropDetails.instructions || "", note: model.dropDetails.note || "", instructionImgs: model.dropDetails.instructionImgs ?? []});
        }
        model.pickDetails.customSetting = customSettingOfPick;
        model.dropDetails.customSetting = customSettingOfDrop;
      }
    }
    const questions = [];
    for(let item of this.questions) {
      let found = (model.questions || []).find(it => it.question === item);
      if (found) questions.push(found);
      else questions.push({ question: item, answer: 'default' });
    }
    model.questions = questions;
    return model;
  }

  getAnswerStr(item): string {
    if (item.answer === 'default') {
      return 'N/A';
    }
    return item.answer;
  }

  protected afterBindModel(): void {
    this.onAddressVerified(this.model?.pickAddr?.metadata);
    if(this.model?.clientIds?.length > 1) this.isMultipleClients = true;
  }

  isMultiLines(key: string): boolean {
    switch (key) {
      case 'note':
      case 'instructions':
        return true;
      default:
        return false;
    }
  }

  isDisableServiceOptionItem(item): boolean {
    let arr = this.getItemValue('serviceOptions');
    for (let obj of arr) {
      if (obj.name == item) {
        return true;
      }
    }
    return false;
  }

  removeServiceOption(index: number) {
    let arr = this.formInput.get('serviceOptions').value;
    let newArr = [];  // create a new array to make form recognize the change and UI is updated automatically
    for (let i = 0; i < arr.length; i++) {
      if (i == index) {
        continue;
      }
      newArr.push(arr[i]);
    }
    this.formInput.get('serviceOptions').setValue(newArr);
  }

  public timezone = '';
  onAddressVerified(data: AddressUSSmartyMetadata) {
    if (!data) return;
    this.timezone = data.timeZone;
    if (data.rdi == 'Residential') {
      this.setItemValue('addressType', Const.AddressType.residential);
    } else if (data.rdi == 'Commercial') {
      this.setItemValue('addressType', Const.AddressType.commercial);
    }
  }

  getServiceOptions_Full(serviceOptionIds) {
    if (!serviceOptionIds) {
      return;
    }
    let serviceOptionNames = [];
    for (let id of serviceOptionIds) {
      let name = MasterData.getServiceOptionName(id);
      if (name) {
        serviceOptionNames.push(name);
      }
    }
    return serviceOptionNames.length? serviceOptionNames : null;
  }

  showPhoneNumberValue(phoneNumber) {
    if (!phoneNumber) return '';
    return InputHelper.formatPhone(phoneNumber);
  }

  showPhoneExt(data) {
    return data?.phoneExtension ? `Ext ${data?.phoneExtension}` : ''
  }

  goToHistory(){
    this.onClose()
    this.router.navigate([this.routeAdminLocationList, 'history', this.model._id], {queryParams: {entity: "warehouses"}});
  }

  onInputChanged(event, key) {
    switch (key) {
      case 'phone':
      case 'secondaryContact.phone':
        return InputHelper.handleInputChangePhone(event, <FormControl>this.formInput.get(key));
      default:
        return super.onInputChanged(event, key);
    }
  }

  onInputKeyPress(event, key) {
    switch (key) {
      case 'phone':
      case 'secondaryContact.phone':
        return InputHelper.handleInputKeyPressNumberOnly(event);
      default:
        return super.onInputKeyPress(event, key);
    }
  }

  onChangeOrganization(value) {
    if (this.isCrossDock && value === 'ORG_0') {
      let isWarpCrossdockControl = this.formInput.get('isWarpCrossdockLocation');
      isWarpCrossdockControl.setValue(true);
      isWarpCrossdockControl.updateValueAndValidity();
    }
  }

  onChangeWarehouseType(value) {
    if (value === Const.WarehouseTypes.crossdock && this.formInput.get('orgId').value === 'ORG_0'){
      let isWarpCrossdockControl = this.formInput.get('isWarpCrossdockLocation');
      isWarpCrossdockControl.setValue(true);
      isWarpCrossdockControl.updateValueAndValidity();
    }
    if(value !== Const.WarehouseTypes.crossdock){
      let supportManifestControl = this.formInput.get('supportManifestFunction');
      supportManifestControl.setValue(false);
      let isWarpCrossdockControl = this.formInput.get('isWarpCrossdockLocation');
      isWarpCrossdockControl.setValue(false);
      isWarpCrossdockControl.updateValueAndValidity();
    }
    if(value !== Const.WarehouseTypes.external) {
      this.formGroupDeclaration.pickAddr.readOnly = false;
      this.formGroupDeclaration.pickAddr.submitReadOnly = false;

      let formControl = this.formInput.get('pickAddr');
      formControl.enable();
      formControl.clearValidators();
      formControl.updateValueAndValidity();
      return;
    }

    this.formGroupDeclaration.pickAddr.readOnly = true;
    this.formGroupDeclaration.pickAddr.submitReadOnly = true;

    let formControl = this.formInput.get('pickAddr');
    formControl.disable();
    formControl.clearValidators();
    formControl.updateValueAndValidity();

    const defaultAddr = {
      "street": "",
      "street2": null,
      "city": "",
      "state": "",
      "countryAlpha2Code": "US",
      "manualAddressEntry": true,
      "zipcode": "",
      "metadata": {
        "isExternal": true,
        "timeZone": "Eastern",
        "timeZoneStandard": "America/New_York",
        "latitude": 0,
        "longitude": 0
      }
    }
    this.setItemValue('pickAddr', defaultAddr);
  }

  get isCrossDock(){
    let type = this.formInput?.get('warehouseType')?.value;
    return type == Const.WarehouseTypes.crossdock;
  }

  get isCrossDockSupportManifestFeature() {
    return this.formInput.get('supportManifestFunction').value || false;
  }

  get isWarpCrossdockLocation() {
    return this.formInput.get('isWarpCrossdockLocation').value || false;
  }

  get requiredCheckIn() {
    return this.formInput.get('requiredCheckIn').value || false;
  }

  onFileImageSelectedChange(key, files) {
    const keys = key.split('.');
    if (key.includes('customSetting') && keys[2]) {
      let json: any = super.getFormData_JSON(true);
      let clientId = json['pickDetails']?.['customSetting']?.[parseInt(keys[2])]?.id;
      if (clientId) {
        keys[2] = `CUSTOMER_${clientId}`;
      }
      this.fileToUpload[keys.join('.')] = files;
    } else {
      this.fileToUpload[key] = files;
    }
  }
  isDeletedImage = false;
  onFileUploadedImageChange(key, files) {
    let form = this.formInput.get(key);
    form.setValue(files);
    this.isDeletedImage = true;
  }

  onBtnSave() {
    if (!this.needUpdate) {
      return;
    }
    if (this.isCreateNew) {
      this.createData();
    } else {
      this.updateData();
    }
  }
  isUploading = false;
  protected updateData() {
    this.isUploading = true;
    const formData = this.getFormData(false);
    const id = this.model.id ?? this.model._id;
    this.api.putFormData(`${Const.APIURI_WAREHOUSES}/${id}/update`, formData).subscribe((resp) => {
      this.isUploading = false;
      this.onUpdateSuccess(resp);
    }, (err) => {
      this.isUploading = false;
      console.log(err);
      this.showErr(err);
    });
  }

  protected createData() {
    this.isUploading = true;
    const formData = this.getFormData(true);
    this.api.postFormData(`${Const.APIURI_WAREHOUSES}/create`, formData).subscribe((resp) => {
      this.isUploading = false;
      this.onUpdateSuccess(resp);
    }, (err) => {
      this.isUploading = false;
      console.log(err);
      this.showErr(err);
    });
  }

  fileToUpload: { [key: string]: any } = {};

  protected getFormData(isCreateNew: boolean): FormData {
    let jsonData = this.getFormData_JSON(isCreateNew);
    let fileKeys = Object.keys(this.fileToUpload);
    let formData = new FormData();
    for (let key of fileKeys) {
      const count = this.fileToUpload[key]?.length;
      if (count > 0) {
        for (let index = 0; index < count; index++) {
          const file = this.fileToUpload[key][index];
          formData.append(
            `${key}.${index}`,
            file.file,
            file.fileName,
          );
        }
      } else if (this.model && this.model[key] && this.model[key].deleted) {
        jsonData[key] = null;
      }
    }
    formData.append("params", JSON.stringify(jsonData));
    return formData;
  }

  viewImageItem(imgUrl) {
    if (!imgUrl) return;
    DialogService.previewImgs([imgUrl], 0);
  }

  onAddAutoRecipient() {
    this.addItemToFormArray('autoRecipients');
  }

  onRemoveAutoRecipient(index: number) {
    this.removeItemInFormArray('autoRecipients', index);
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WarehouseFormV2Map } from '.';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    WarehouseFormV2Map,
  ],
  exports: [
    WarehouseFormV2Map,
  ]
})
export class ModuleWarehouseFormV2Map {}
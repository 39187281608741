import { BaseFormItem } from "@app/admin/base/form-item";
import { Component, Input } from "@angular/core";
import { Const } from "@const/Const";
import { Const as WarpConst } from "@wearewarp/universal-libs";
import { FormArray, Validators } from "@angular/forms";
import { Utils } from '@services/utils';

@Component({
  selector: '[warehouse-task]',
  templateUrl: './index.html',
  styleUrls: [
    './index.scss',
    "../detail.scss",
    "../../../../styles/row-col.scss",
    "../../../../styles/form-v2.scss",
  ]
})
export class WarehouseTask extends BaseFormItem {

  protected formGroupDeclaration: FormGroupDeclaration = {
    inbound: {label: 'Inbound', type: 'formArray', initialValue: [{}], childItem: {
      taskType: {label: 'Task Type', inputType: 'hidden'},
      levels: {label: 'Level', type: 'formGroup', childItem: {
        shipment: { label: 'Shipment', type: 'boolean', required: true, initialValue: false },
        item: { label: 'Item', type: 'boolean', initialValue: false },
      }},
      isWarning: {label: '', inputType: 'hidden', initialValue: false },
      reason: { label: '', placeHolder: 'Choose reason', required: false },
    }},
    outbound: {label: 'Outbound', type: 'formArray', initialValue: [{}], childItem: {
      taskType: {label: 'Task Type', inputType: 'hidden'},
      levels: {label: 'Level', type: 'formGroup', childItem: {
        shipment: { label: 'Shipment', type: 'boolean', required: false, initialValue: false },
        item: { label: 'Item', type: 'boolean', initialValue: false },
      }},
      isWarning: {label: '', inputType: 'hidden', initialValue: false },
      reason: { label: '', placeHolder: 'Choose reason', required: false },
    }}
  };
  public taskSettings = [];
  private stages = [WarpConst.WarehouseTaskStage.inbound, WarpConst.WarehouseTaskStage.outbound];

  @Input() set myModel(value) {
    let currentModel = this.model;
    this.model = value;
    if(currentModel) this.bindDataModel(value);
  }

  get shouldCreateFormImmediately() {
    return false;
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.initFormValues();
  }

  protected getApiUrl(): string {
    return Const.APIURI_WAREHOUSES;
  }

  protected onUpdateSuccess(resp) {
    super.onUpdateSuccess(resp);
  }

  private async getWarehouseTasks() {
    const url = Const.APIV2(`${Const.APIURI_METADATA}/ORG_0/WAREHOUSE/warehouse-tasks`)
    const response = await this.api.GET(url).toPromise();
    const value = response.value || '[]';
    const settings = JSON.parse(value);
    let inboundTasks = [];
    let outboundTasks = [];
    for(let setting of settings) {
      const levels = setting.levels;
      const levelObj = {}
      for(let level of levels) {
        let types: any[] = [WarpConst.WarehouseTaskType.uploadBOL];
        const _id = this.model.id;
        if(_id === "add") types = [...types, WarpConst.WarehouseTaskType.addWeight, WarpConst.WarehouseTaskType.uploadProductPhoto];
        if(types.includes(setting.type)) levelObj[level] = true;
        else levelObj[level] = false;
      }

      if(setting.stages.includes(WarpConst.WarehouseTaskStage.inbound)) {
        inboundTasks.push({
          taskType: setting.type,
          levels: levelObj,
          reasons: setting.reasons || []
        })
      }
      if(setting.stages.includes(WarpConst.WarehouseTaskStage.outbound)) {
        outboundTasks.push({
          taskType: setting.type,
          levels: setting.type === WarpConst.WarehouseTaskType.uploadBOL ?  { ...levelObj, shipment: false } : levelObj,
          reasons: setting.reasons || []
        })
      }
    }
    const taskSettings = [
      { type: WarpConst.WarehouseTaskStage.inbound, tasks: inboundTasks },
      { type: WarpConst.WarehouseTaskStage.outbound, tasks: outboundTasks }
    ];
    this.taskSettings = taskSettings;

    return taskSettings;
  }

  public getLevels(mode, index, key) {
    const value = this.getItemValue(`${mode.type}[${index}].${key}`);
    const tasks = mode.tasks;
    const task = tasks.find((it) => it.taskType === value);
    const levels = task?.levels || {};
    return Object.keys(levels);
  }

  public getReasons(mode, index, key) {
    const value = this.getItemValue(`${mode.type}[${index}].${key}`);
    const tasks = mode.tasks;
    const task = tasks.find((it) => it.taskType === value);
    return task?.reasons || []
  }

  public getIsWarning(mode, index, key): boolean {
    const value = this.getItemValue(`${mode.type}[${index}].${key}`);
    return value
  }

  public isLoadingWarehouseTasks = true;
  async initFormValues() {
    this.isLoadingWarehouseTasks = true;
    const taskSettings = await this.getWarehouseTasks();
    this.isLoadingWarehouseTasks = false;
    if (taskSettings.length) {
      let inboundTasks = {};
      let outboundTasks = {};
      for (let key of this.stages) {
        const { tasks } = this.taskSettings.find((it) => it.type === key) || {};
        if (key === WarpConst.WarehouseTaskStage.inbound) {
          for (let task of tasks) {
            inboundTasks = this.getTasks(task, inboundTasks, false, true);
          }
        }
        if (key === WarpConst.WarehouseTaskStage.outbound) {
          for (let task of tasks) {
            outboundTasks = this.getTasks(task, outboundTasks, false, true);
          }
        }
      }

      this.formGroupDeclaration.inbound.initialValue = Object.values(inboundTasks);
      this.formGroupDeclaration.outbound.initialValue = Object.values(outboundTasks);
    }

    this.createFormInput(this.model);
    this.setEnableFormGroup(true);
  }

  protected beforeBindModel(model: any) {
    this.convertData(model);
    return model;
  }

  private convertData(model) {
    if (model?.warehouseTasks?.length) {
      const warehouseTasks = model.warehouseTasks || [];
      let inboundTasks = {};
      let outboundTasks = {};
      for (let key of this.stages) {
        const { tasks } = this.taskSettings.find((it) => it.type === key) || {};
        if(key === WarpConst.WarehouseTaskStage.inbound) {
          for(let task of tasks) {
            inboundTasks = this.getTasks(task, inboundTasks, false)
          }
        }
        if(key === WarpConst.WarehouseTaskStage.outbound) {
          for(let task of tasks) {
            outboundTasks = this.getTasks(task, outboundTasks, false)
          }
        }
      }
      for (let task of warehouseTasks) {
        if(task.stage === WarpConst.WarehouseTaskStage.inbound) {
          inboundTasks = this.getTasks(task, inboundTasks)
        }
        if(task.stage === WarpConst.WarehouseTaskStage.outbound) {
          outboundTasks = this.getTasks(task, outboundTasks)
        }
      }

      model.inbound = Object.values(inboundTasks);
      model.outbound = Object.values(outboundTasks);
    }
  }

  private getTasks(task, obj, value = true, isUse = false) {
    if(!obj[task.taskType]) {
      let levels = isUse ? task.levels : {}
      if(task.level) {
        levels[task.level] = value;
      }
      obj[task.taskType] = {
        taskType: task.taskType,
        levels: levels,
        reasons: task?.reasons || []
      }
    }
    else {
      const levels = obj[task.taskType]['levels'];
      if(task.level && !levels[task.level]) {
        let levels = obj[task.taskType]['levels'];
        levels[task.level] = task?.reason ? false : value;
        obj[task.taskType]['levels'] = levels;
      }
      if(task?.reason) obj[task.taskType]['reason'] = task?.reason;
      obj[task.taskType]['isWarning'] = task?.isWarning;
    }

    return obj;
  }

  public getFormData_JSON(isCreateNew: boolean): object {
    let data: any = super.getFormData_JSON(isCreateNew);
    data.warehouseTasks = this.getWarehouseTasksData(data);
    return data;
  }

  private getWarehouseTasksData(formDataJson) {
    const settings = {
      inbound: formDataJson.inbound ? formDataJson.inbound : this.model.inbound,
      outbound: formDataJson.outbound ? formDataJson.outbound : this.model.outbound
    };
    let warehouseTasks = [];
    for (let key of this.stages) {
      const tasks = settings[key] || [];
      const defaultTasks = this.taskSettings.find(it => it.type === key)?.tasks || []
      if (!tasks.length) continue;
      const taskMapped = [];
      for (let task of tasks) {
        const levels = task.levels || {};
        const defaultLevels = defaultTasks.find(it => it.taskType === task.taskType)?.levels || {};
        if (!Object.values(levels).length) continue;
        for (let k of ["shipment", "item"]) {
          let isWarning = levels[k] ? false : !!(Utils.isBoolean(defaultLevels[k]) && task.reason);
          if (levels[k] || isWarning) {
            taskMapped.push({
              taskType: task.taskType,
              level: k,
              reason: isWarning ? task.reason : '',
              isWarning: isWarning,
              stage: key,
            });
          }
        }
      }
      warehouseTasks = [...warehouseTasks, ...taskMapped];
    }
    return warehouseTasks;
  }

  onCheckedChange(mode, i, key, item) {
    const rootKey = `${mode.type}[${i}].levels.${key}`;
    const isChecked = this.getItemValue(rootKey);
    let fc = (<FormArray>this.formInput.get(mode.type))?.at(i)?.get('reason');
    let fcWarning = (<FormArray>this.formInput.get(mode.type))?.at(i)?.get('isWarning');

    if (fc) {
      if (!isChecked) {
        fcWarning.setValue(true);
        fc.addValidators(Validators.required);
      } else {
        fcWarning.setValue(false);
        fc.setValue(undefined);
        fc.removeValidators(Validators.required);
      }
      fc.validate();
      fc.updateValueAndValidity();
    }
  }

  getTaskName(type, index, key) {
    const value = this.getItemValue(`${type}[${index}].${key}`);
    if(value === Const.WarehouseTaskType.assignBarcode) return 'Assign Warp Barcode';
    return Const.WarehouseTaskName[value] || value;
  }
}
<form *ngIf="!isLoadingWarehouseTasks" class="form-detail" [formGroup]="formInput" nz-form>
  <div class="group-content">
    <div class="tdm-row cell-spacing">
      <div *ngFor="let mode of taskSettings;" [formArrayName]="mode.type" class="tdm-col tdm-col-2">
        <label class="title">{{getLabel(fullKey(mode.type))}} </label>

        <div *ngFor="let item of getArrayControls(mode.type); let i = index" class="form-setting index-{{ i }}">
          <form nz-form [formGroupName]="i">
            <div class="tdm-row cell-spacing">
              <div class="tdm-col tdm-col-{{ childKey === 'levels' ? 4 : 3 }}" *ngFor="let childKey of ['taskType', 'levels', 'reason'];">
                <div>
                  <ng-container *ngIf="i === 0">
                    <div class="form-label-v2">
                      <ng-container *ngIf="childKey === 'reason'">
                        &nbsp;
                      </ng-container>
                      <ng-container *ngIf="childKey !== 'reason'">
                        {{getLabel(fullKey(mode.type, childKey))}}
                        <span *ngIf="isRequired(fullKey(mode.type, childKey))" class="label-mark-required"></span>
                      </ng-container>
                    </div>
                  </ng-container>

                  <ng-container [ngSwitch]="childKey">
                    <div *ngSwitchCase="'taskType'" [ngClass]="childKey">
                      <ng-container *ngIf="getLevels(mode, i, 'taskType').length">
                        <label>{{ getTaskName(mode.type, i, childKey) }}</label>
                        <input
                          nz-input
                          [formControlName]="childKey"
                          [type]="getInputType(childKey)"
                          [placeholder]="getPlaceHolder(childKey)"
                          (input)="onInputChanged($event, childKey)"
                          (keypress)="onInputKeyPress($event, childKey)"
                        />
                      </ng-container>
                    </div>

                    <div *ngSwitchCase="'levels'" [ngClass]="childKey">
                      <form nz-form [formGroupName]="childKey" class="group-crossdock">
                        <div class="item-crossdock" *ngFor="let key of getLevels(mode, i, 'taskType')">
                          <nz-form-item>
                            <nz-form-control>
                              <label 
                                class="label-text" nz-checkbox [formControlName]="key"
                                (nzCheckedChange)="onCheckedChange(mode, i, key, item)"
                              >{{getLabel(fullKey(mode.type, childKey, key))}}</label
                              >
                            </nz-form-control>
                          </nz-form-item>
                        </div>
                      </form>
                    </div>

                    <div *ngSwitchCase="'reason'" [ngClass]="childKey">
                      <div class="item-reason" [ngClass]="{'hidden': !getIsWarning(mode, i, 'isWarning'), 'show': getIsWarning(mode, i, 'isWarning') }">
                        <nz-form-item>
                          <nz-form-control>
                            <nz-select style="width: 100%;"
                              nzAllowClear nzShowSearch
                              [formControlName]="childKey" 
                              [nzPlaceHolder]="getPlaceHolder(fullKey(mode.type, childKey))" 
                              (ngModelChange)="onInputChanged($event, childKey)"
                            >
                              <nz-option *ngFor="let option of getReasons(mode, i, 'taskType')" [nzValue]="option" [nzLabel]="option"></nz-option>
                            </nz-select>
                        </nz-form-control>
                        </nz-form-item>
                      </div>

                      <div class="isWarning">
                        <input
                          nz-input
                          [formControlName]="'isWarning'"
                          [type]="getInputType('isWarning')"
                          [placeholder]="getPlaceHolder('isWarning')"
                          (input)="onInputChanged($event, 'isWarning')"
                          (keypress)="onInputKeyPress($event, 'isWarning')"
                        />
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</form>

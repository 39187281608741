import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { SearchBoxModule } from '@libs/search-box/search-box.module';
import { UserListComponent } from './list';
import { UserDetailComponent } from './detail';
import { DetailModule } from '../base/detail.module';
import { ClientModule } from '../clients/module';
import { SelectBySearchingModule } from '@libs/select-client/module';
import { UserFilter } from './user-filter';
import { UserStatus } from './user-status';
import { ModuleWhenByForList } from '../components/whenby-for-list/module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NzToolTipModule,
    NzButtonModule,
    NzTableModule,
    SearchBoxModule,
    NzDropDownModule,
    NzFormModule,
    NzSelectModule,
    NzInputModule,
    NzIconModule,
    NzCheckboxModule,
    DetailModule,
    ClientModule,
    SelectBySearchingModule,
    ModuleWhenByForList,
  ],
  declarations: [
    UserListComponent,
    UserDetailComponent,
    UserFilter,
    UserStatus
  ],
  exports: [
    UserListComponent,
    UserDetailComponent,
  ],
  providers: [
  ]
})
export class UserModule {}

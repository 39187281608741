import { Component, Input, OnInit } from "@angular/core";
import { customMapboxConfig } from "@app/admin/components/mapbox-static/custom-mapbox";
import { MasterData } from "@services/master.data";
import { LatLng } from "@wearewarp/types/data-model";
import mapboxgl from 'mapbox-gl';
import { Feature, Polygon } from 'geojson';
import * as turf from '@turf/turf';
import { WarehouseDataForMap } from "./interface";
import { Const } from "@const/Const";

@Component({
  selector: '[warehouse-form-v2-map]',
  templateUrl: './view.html',
  // styleUrls: ['./style.scss']
})
export class WarehouseFormV2Map implements OnInit {
  @Input() warehouse: WarehouseDataForMap;
  private map: mapboxgl.Map;

  ngOnInit(): void {
    this.initMap();
  }

  private initMap() {
    mapboxgl.accessToken = MasterData.mapboxToken;
    customMapboxConfig(mapboxgl);
    const center: [number, number] = this.warehouse.location.latitude ? [this.warehouse.location.longitude, this.warehouse.location.latitude] : Const.MAP_LOCATION_LA;
    this.map = new mapboxgl.Map({
      container: 'map-container',
      style: 'mapbox://styles/mapbox/streets-v11',
      center: center,
      zoom: 17,
    });
    this.map.on('load', () => {
      const arr: LatLng[] = [
        ...this.addWarehouseBounds(),
        ...this.addWarehouseLocation(),
      ];
      this.fitBounds(arr);
    })
  }

  private addWarehouseBounds(): LatLng[] {
    if (!this.warehouse?.bounds?.northeast?.latitude) return [];
    const {northeast, southwest} = this.warehouse.bounds;
    const line = turf.lineString([[northeast.longitude, northeast.latitude], [southwest.longitude, southwest.latitude]]);
    const box = turf.bbox(line);
    const data: Feature<Polygon> = turf.bboxPolygon(box);
    this.map.addLayer({
      id: 'location-bounds',
      type: 'line',
      paint: {
        'line-color': '#3386c0',
        'line-width': 2,
        'line-opacity': 0.9
      },
      source: {
        type: 'geojson',
        data: data,
      }
    });
    return [northeast, southwest];
  }

  private addWarehouseLocation(): LatLng[] {
    if (!this.warehouse?.location?.latitude) return[];
    const location = this.warehouse.location;
    new mapboxgl.Marker()
      .setLngLat([location.longitude, location.latitude])
      .setPopup(new mapboxgl.Popup({closeButton: false}).setHTML(`GPS location ${location.latitude}, ${location.longitude}`))
      .addTo(this.map);
    return [location];
  }

  private fitBounds(locations: LatLng[]) {
    if (locations.length == 0) return;
    const arrLng = locations.map(it => it.longitude);
    const arrLat = locations.map(it => it.latitude);
    const minLng = Math.min(...arrLng);
    const maxLng = Math.max(...arrLng);
    const minLat = Math.min(...arrLat);
    const maxLat = Math.max(...arrLat);
    this.map.fitBounds([[minLng, minLat], [maxLng, maxLat]], {padding: 50, maxZoom: 20});
  }
}
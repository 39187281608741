<div style="height: 35px;" class="flex">
    <div class="flex1">
    <nz-input-group [nzSuffix]="inputClearTpl" class="right10" style="width: 200px;">
        <input nz-input [(ngModel)]="searchKey" (ngModelChange)="onSearchKeyChange($event)" placeholder="Search by PO or ids">
    </nz-input-group>
    <ng-template #inputClearTpl>
        <span
          nz-icon
          class="ant-input-clear-icon"
          nzTheme="fill"
          nzType="close-circle"
          *ngIf="searchKey"
          (click)="clearSearch()"
        ></span>
    </ng-template>
    <button class="right10" nz-button (click)="onRefresh()"><i *ngIf="loading" nz-icon nzType="loading"
            nzTheme="outline"></i> Refresh</button>
    <ng-container *ngIf="setOfCheckedId.size > 0">
        <button class="right10" nz-button (click)="onBtnDownloadCsv()">Download CSV <span nz-icon nzType="download" nzTheme="outline"></span></button>
        <button class="right10" nz-button (click)="onBtnDownloadBols()">
            Download BOLs 
            <span *ngIf="loadingBol" nz-icon nzType="loading"></span>
            <span *ngIf="!loadingBol" nz-icon nzType="download" nzTheme="outline"></span>
        </button>
        <button class="right10" nz-button nzTrigger="hover" nz-dropdown [nzDropdownMenu]="menuRoute">
            More actions <span nz-icon nzType="more" nzTheme="outline"></span>
        </button>
        <nz-dropdown-menu #menuRoute="nzDropdownMenu">
            <ul nz-menu nzSelectable>
                <li nz-menu-item [nzDisabled]="!this.canDo.canRouteOutbound" (click)="onBtnManualRouteShipment()">
                    <span nz-tooltip [nzTooltipTitle]="getTooltipActions('createOutboundRoute', !this.canDo.canRouteOutbound)">Create Outbound Route</span>
                </li>
                <li nz-menu-item [nzDisabled]="!this.canDo.canRouteInbound" (click)="onBtnManualRouteInbound()">
                    <span nz-tooltip [nzTooltipTitle]="getTooltipActions('createInboundRoute', !this.canDo.canRouteInbound)">Create Inbound Route</span>
                </li>
                <li nz-menu-divider></li>
                <li nz-menu-item (click)="onBtnCopyInboundIds()">Copy Inbound Leg Ids</li>
                <li nz-menu-item (click)="onBtnCopyOutboundIds()">Copy Outbound Leg Ids</li>
                <li nz-menu-item (click)="onBtnCopyMainIds()">Copy Main Shipment Ids</li>
                <li nz-menu-item (click)="onBtnCopyOrderIds()">Copy Order Ids</li>
                <li nz-menu-divider></li>
                <li nz-menu-item [nzDisabled]="!this.canDo.bulkUpdateScheduledPickup" (click)="onEditScheduledPickup()">
                    <span nz-tooltip [nzTooltipTitle]="getTooltipActions('updateScheduledPickupTime', !this.canDo.bulkUpdateScheduledPickup)">Update Scheduled Pickup Time</span>
                </li>
                <li nz-menu-item [nzDisabled]="!this.canDo.bulkUpdateScheduledInject" (click)="onEditScheduledInject()">
                    <span nz-tooltip [nzTooltipTitle]="getTooltipActions('updateScheduledInjectionTime', !this.canDo.bulkUpdateScheduledInject)">Update Scheduled Injection Time</span>
                </li>
                <li nz-menu-item [nzDisabled]="!this.canDo.bulkUpdateScheduledRelease" (click)="onEditReleasePickup()">
                    <span nz-tooltip [nzTooltipTitle]="getTooltipActions('updateScheduledReleaseTime', !this.canDo.bulkUpdateScheduledRelease)">Update Scheduled Release Time</span>
                </li>
                <li nz-menu-item [nzDisabled]="!this.canDo.bulkUpdateScheduledDeliver" (click)="onEditScheduledDeliver()">
                    <span nz-tooltip [nzTooltipTitle]="getTooltipActions('updateScheduledDeliveryTime', !this.canDo.bulkUpdateScheduledDeliver)">Update Scheduled Delivery Time</span>
                </li>
            </ul>
        </nz-dropdown-menu>
    </ng-container>
    </div>
    <div>
        Total Pallets: <span class="font-medium">{{totalPallet}}</span>
        <span *ngIf="selectedPalletCount"> | <span class="font-medium">{{selectedPalletCount}}</span> selected</span>
    </div>
</div>

<nz-table nzBordered="true" #rowSelectionTable [nzScroll]="{ x: 'scroll' }" [nzFrontPagination]="false"
    nzSize="small" nzShowPagination="false" nzShowSizeChanger [nzData]="data">
    <thead>
        <tr>
            <th nzWidth="45px" [nzChecked]="checkedAll" [nzIndeterminate]="indeterminate"
                (nzCheckedChange)="onAllChecked($event)">
            </th>
            <th nzWidth="100px" [nzFilterMultiple]="true" [nzFilters]="statusFilters"
                (nzFilterChange)="filterFnStatus($event)">Status</th>
            <!-- <th nzWidth="75px">Created</th> -->
            <th nzWidth="85px">Shipment</th>
            <th nzWidth="140px" [nzFilterMultiple]="true" [nzFilters]="clientFilters"
                (nzFilterChange)="filterFnClient($event)">Client Name</th>
            <th nzWidth="200px">PO#</th>
            <th nzWidth="200px">Inventories</th>
            <th nzWidth="100px">Weight</th>
            <th nzWidth="150px">Inbound Leg</th>
            <th nzWidth="150px">Outbound Leg</th>
            <th nzWidth="120px">Scheduled Pickup</th>
            <th nzWidth="100px">Picked-up Date</th>
            <th nzWidth="240px">Pickup Location</th>
            <th nzWidth="120px">Scheduled Release</th>
            <th nzWidth="240px" [nzFilterMultiple]="true" [nzFilters]="deliveryFilters"
                (nzFilterChange)="filterFnDelivery($event)">Delivery Location</th>
            <th nzWidth="90px">Injected</th>
            <th nzWidth="90px">Released</th>
        </tr>
    </thead>
    <tbody>
        <!-- <tr *ngIf="unScheduled && unScheduled.length" style="background-color: #f8f8f8;">
            <td style="padding: 0px;"></td>
            <td style="padding: 0px 8px;" colspan="14">Outbound Not Scheduled</td>
        </tr> -->
        <ng-container *ngFor="let row of data">
            <tr (onItemSelected)="onItemChecked($event)" [selected]="setOfCheckedId.has(row.id)"
                warehouse-item-list-item [item]="row" (click)="onExpandChange(row)" data-id="{{ row.id }}"></tr>
            <tr [nzExpand]="expandSet.has(row.id)">
                <div [viewed]="row.expanded" warehouse-item-content [item]="row"></div>
            </tr>
        </ng-container>
        <!-- <ng-container *ngFor="let group of scheduled">
            <tr style="height: 1px; background-color: #444;">
                <td style="padding: 3px;"></td>
                <td style="padding: 3px;" colspan="14"></td>
            </tr>
            <ng-container *ngFor="let row of group">
                <tr (onItemSelected)="onItemChecked($event)" [selected]="setOfCheckedId.has(row.id)"
                    warehouse-item-list-item [item]="row" (click)="onExpandChange(row)"></tr>
                <tr [nzExpand]="expandSet.has(row.id)">
                    <div [viewed]="row.expanded" warehouse-item-content [item]="row"></div>
                </tr>
            </ng-container>
        </ng-container> -->
    </tbody>
</nz-table>